import React, { useMemo } from 'react';
import {
  LearningStepsMobile,
  LearningStepsDesktop,
} from '@nn-virtual-pen/education/features/learning-steps-page';
import {
  ContentContext,
  useTranslate,
  useUserContext,
} from '@nn-virtual-pen/education/data-access';
import { GenericLayout } from '../layouts';
import { Helmet } from 'react-helmet';
import { cond, prop, T, pipe, path, find, filter, includes, head } from 'ramda';
import { withRequireConfig } from '../hoc';
import { Initialization } from '../components/initialization';
import { useStaticQuery, graphql } from 'gatsby';

export const Learning = () => {
  const { lang } = useTranslate();
  const { configuration } = useUserContext();
  const { product, country } = configuration || {};

  const data = useStaticQuery(graphql`
    query StepsFiles {
      allFile(filter: { name: { regex: "/steps-\\\\w+/" } }) {
        nodes {
          publicURL
          name
        }
      }
    }
  `);

  const getFilePath = pipe(
    path(['allFile', 'nodes']),
    filter(
      pipe(
        prop('name'),
        includes(`${product.toLowerCase()}-${country.toLowerCase()}`)
      )
    ),
    (nodes: Array<{ name: string; publicURL: string }>) =>
      find(pipe(prop('name'), includes(lang)), nodes) || head(nodes),
    prop('publicURL')
  );

  const filePath = useMemo(() => getFilePath(data), [data, product, country]);

  return (
    <GenericLayout withMedia>
      {({ desktop }) => (
        <>
          <Helmet title="Learning" />
          <ContentContext contentPath={filePath}>
            {desktop ? <LearningStepsDesktop /> : <LearningStepsMobile />}
          </ContentContext>
        </>
      )}
    </GenericLayout>
  );
};

export default withRequireConfig(
  cond([
    [prop('isLoading'), Initialization],
    [T, Learning],
  ])
);
